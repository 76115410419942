


const About = () =>{
    return(
        <div className="container border-md rounded rounded-5 bg-opacity-10 about-container p-2 shadow-md mt-5">
            <h1 className="fs-5 text-center ">Who am I?</h1>
            <p className='text-justify'>
                My name is Sadric, I'm acquiring knowledge to become a bulletproof data engineer with a bachelor's degree in Computer Science.
                I have solid knowledge of computer hardware and operating systems (OS).
                My passion for the technology world drove me to pursue this degree.
                Most people think there is no more innovation, but I don't believe that. It's what keeps me motivated.
                So far, I'm enjoying the learning curve as I work on personal projects to hone my skills. 
                Most of my projects are on <a href="https://github.com/sadric5" className='githubLink'>GitHub</a> which you can
                 look at if you are interested in giving me some feedback or for your own interest.
                You can contact me <a href="/contact">Here</a>
            </p>
        

            <h2 className="fs-5 text-center">What I do?</h2>
            <ul className="shadow m-4">
                <li>Data Engineering (Apache airflow, kafka)</li>
                <li>Sofware development</li>
                <li>Web Scraping</li>
                <li>Connect to a database with python (mySQL)</li>
                <li>Build API with Django Restframework (Python)</li>
                <li>Create Websiste</li>
                <li>Lunch cloud server ( AWS /Linode)</li>
                <li>Write some script with Bash Or Python</li>
                <li>Windows machine troubleshooting</li>
                <li>and others related to sofware engeering</li>
            </ul>

            <h2 className="fs-5 text-center">Technical Skills</h2>
            <div className="row">
                <div className="col-sm fs-5 shadow m-1">
                    <div className="text-center fw-bold">Programming Languages</div>
                    <ul>
                        <li>Python</li>
                        <li>JavaScript</li>
                        <li>Html/CSS</li>
                        <li>Java</li>
                        <li>C</li>
                        <li>SQL</li>
                    </ul>
                </div>
                <div className="col-sm fs-5 shadow m-1">
                    <div className="text-center fw-bold">Framework</div>
                    <ul>
                        <li>Django</li>
                        <li>React</li>
                        <li>React Native (Mobile App)</li>
                        <li>Bootstrap</li>
                    </ul>
                </div>
                
            </div>

            <div className="row my-2">
                <div className="col-sm fs-5 shadow m-1">
                    <div className="text-center fw-bold">Cloud Provider</div>
                    <ul>
                        <li>AWS (Amazom Web Service)</li>
                        <li>Azure (Microsoft)</li>
                        <li>Linode</li>
                        
                    </ul>
                </div>
                <div className="col-sm fs-5 shadow m-1">
                    <div className="text-center fw-bold">Cloud Service</div>
                    <ul>
                        <li>VM (like AWS EC2)</li>
                        <li>Azure Storage</li>
                        <li>Aws RDS</li>
                        <li>AWS Lambda</li>
                        <li>AWS API Gateway</li>
                        <li> and more ...</li>
                    </ul>
                </div>
            </div>

            <div className="row my-2">
                <div className="col-sm fs-5 shadow m-1">
                    <div className="text-center fw-bold">Tools</div>
                    <ul>
                        <li>Git</li>
                        <li>Vim</li>
                        <li>Nano</li>
                        <li>SSH (remote server adminstration)</li>
                        <li>Numpy (python)</li>
                        <li>Matplotlib (python)</li>
                    </ul>
                </div>
                <div className="col-sm fs-5 shadow m-1">
                    <div className="text-center fw-bold">Software and hardware</div>
                    <ul>
                        <li>Mac OS X</li>
                        <li>Windows</li>
                        <li>Linux</li>
                        <li>Raspberry PI</li>
                        
                    </ul>
                </div>
            </div>

            <div className="row my-2">
                <div className="col-sm fs-5 shadow m-1">
                    <div className="text-center fw-bold">Certificate</div>
                    <ul>
                        <li>Fundamentals of Red Hat Enterprise</li>
                        <li>Introduction to Cloud Computing</li>
                        <li>Introduction to Containers, Kubernetes, OpenShilft</li>
                        
                    </ul>
                </div>
                <div className="col-sm fs-5 shadow m-1">
                    <div className="text-center fw-bold">Hobbies</div>
                    <ul>
                        <li>Love play Board Game (Chess is my favorite one)</li>
                        <li>Reading (Courage To Be Disliked By Kishimi and Koga is one of my favorite book)</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default About;
