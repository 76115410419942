import React from "react";

// Import images
import home1 from "../images/home1.jpg";
import home2 from "../images/home2.jpg";
import home3 from "../images/home3.jpg";
import analyst from "../images/analyst.jpg"
import code from "../images/code.jpg"
import drone from "../images/drone.jpg"
import space from "../images/space.jpg"


const Home = () => {
  return (
    <div className="container py-5">
      <h1 className="display-4 fw-medium fs-5">Welcome to SEngineering</h1>
      <p className="lead">
        At <strong className="text-primary">SEngineering</strong>, we specialize in cloud services, software development, and solving complex technological challenges.
        Our mission is to build scalable, reliable systems that help businesses thrive in a fast-paced digital world.
      </p>
      <a href="#expertise" className="btn btn-light btn-lg">Learn More</a>

      {/* Image Slider Section */}
      <div id="imageCarousel" className="carousel slide mt-5" data-bs-ride="carousel">
        {/* Carousel Indicators */}
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#imageCarousel"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#imageCarousel"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#imageCarousel"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>

        {/* Carousel Images */}
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src={drone}
              className="d-block w-100 img-fluid rounded"
              alt="Project 1"
            />
            <div className="carousel-caption d-none d-md-block fs-2">
              <h5>Innovation</h5>
              <p className="fs-4">Empowering businesses with cutting-edge solutions, we transform complex challenges into scalable opportunities for growth.</p>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src={code}
              className="d-block w-100 img-fluid rounded"
              alt="Project 2"
            />
            <div className="carousel-caption d-none d-md-block fs-2">
              <h5>Excellence</h5>
              <p className="fs-4">By developing optimized, high-performance software solutions, we ensure your technology is both reliable and future-ready, driving success in the digital world.</p>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src={analyst}
              className="d-block w-100 img-fluid rounded"
              alt="Project 3"
            />
            <div class="carousel-caption d-none d-md-block fs-2">
              <h5>Efficiency</h5>
              <p className="fs-4">From real-time data processing to seamless system integration, our services ensure that your technology works smarter, not harder.</p>
            </div>
          </div>
        </div>

        {/* Carousel Controls */}
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#imageCarousel"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#imageCarousel"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      <p className="text-center my-5 fs-2 fst-italic">
        Explore my <strong><a href="/services">Services</a></strong> and <strong><a href="/products">Products</a></strong> to learn more about what I offer.
      </p>

      <div id="expertise" className="container my-5">
        <h2 className="text-center mb-4 fw-4">Our Expertise</h2>
        <p className="lead text-center bg-info-subtle rounded-3 p-3">
          At <strong>SEngineering</strong>, expertise in cloud services and software development focuses on:
        </p>
        <ul className="list-group list-group-flush list-group-item">
          <li className="list-group-item">Designing scalable systems</li>
          <li className="list-group-item">Optimizing cloud infrastructure</li>
          <li className="list-group-item">Delivering innovative software solutions</li>
        </ul>
        <p className="lead text-center mt-4 bg-info-subtle rounded-3 p-3">
          These efforts tackle complex challenges such as:
        </p>
        <ul className="list-group list-group-flush list-group-item">
          <li className="list-group-item">Real-time data processing</li>
          <li className="list-group-item">Ensuring system reliability under high traffic</li>
          <li className="list-group-item">Integrating diverse technologies into seamless workflows</li>
          <li className="list-group-item">
            Solving software development challenges, such as improving application performance in a distributed environment or optimizing code for large-scale systems
          </li>
        </ul>
      </div>

      
    </div>
  );
};

export default Home;
