const Services = () => {
    return (
      <div style={{ padding: "20px" }}>
        <h1>My Services</h1>
        <ul>
          <li><strong>Data Engineering:</strong> Design and implement scalable data pipelines.</li>
          <li><strong>Cloud Architecture:</strong> Optimize and manage cloud-based systems (AWS, Azure, Linode).</li>
          <li><strong>Software Development:</strong> Build custom software applications tailored to business needs.</li>
          <li><strong>Data Analytics:</strong> Provide insights through data visualization and analytics tools.(Excel, Power BI, Pandas)</li>
        </ul>
      </div>
    );
  };
  
  export default Services;